import {DataTable, Table, TableBody, TableCell, TableContainer, TableHead, TableHeader, TableRow, Checkbox, DataTableSkeleton, TableToolbar, TableBatchActions, TableBatchAction, Loading, InlineNotification} from '@carbon/react';
import {useContext, useEffect, useMemo, useRef, useState} from 'react';
import './additional-providers-table.scss';
import {NetworkContext} from '../lib/context';
import {observer} from 'mobx-react';
import {Save} from '@carbon/icons-react';
import {PercentFormatter} from '../../../lib/formatter';
import {AutoSizer, List} from 'react-virtualized';
import {getTextWidth} from '../../../insight-container/charts/common';
import handleError from '../../../lib/error';

const headers = [
    {key: 'npi', header: 'NPI'},
    {key: 'full_name', header: 'Name'},
    {key: 'first_line_practice_location_address', header: 'Address'},
    {key: 'practice_location_address_city_name', header: 'City'},
    {key: 'practice_location_address_state_name', header: 'State'},
    {key: 'practice_location_address_postal_code', header: 'Zip'},
    {key: 'aligned_group_name', header: 'Provider Group'},
    {key: 'aligned_health_system_name', header: 'Health System Affiliation'},
    // {key: 'final_mips_score', header: 'Resiliency Score'},
    {key: 'marginal_impact_time', header: 'Impact Time'},
    {key: 'marginal_impact_distance', header: 'Impact Distance'},
];

export function UpdateRosterButton({onClick}: {onClick: () => Promise<void>}) {
    let [loading, setLoading] = useState(false);
    const [error, setError] = useState<string | null>(null);
    const handleClick = async () => {
        setLoading(true);
        setError(null);
        try {
            await onClick();
        } catch (err) {
            setError('Failed to update roster. Please try again.');
        } finally {
            setLoading(false);
        }
    };

    return (
        <>
            <TableBatchAction
                className={loading ? 'gap-2' : ''}
                iconDescription="Save"
                renderIcon={loading ? () => <Loading withOverlay={false} small /> : Save}
                onClick={handleClick}
            >
                Update Roster
            </TableBatchAction>
            {error && <InlineNotification kind="error" title="Error" subtitle={error} />}
        </>
    );
}

function AdditionalProvidersTable() {
    const networkOptimizationStore = useContext(NetworkContext);
    const [selection, setSelection] = useState<string[]>([]);
    const [deselection, setDeselection] = useState<string[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);
    const [scrollToExistingProvider, setScrollToExistingProvider] = useState<string | null>(null);
    const {localProviders, existingProviders, existingProviderQueuedForDeletion} = networkOptimizationStore;
    const listRef = useRef(null);

    useEffect(() => {
        setSelection(networkOptimizationStore.selectedAdditionalProviderKeys);
        setDeselection(networkOptimizationStore.deselectedExistingProviderKeys);
    }, [networkOptimizationStore.selectedAdditionalProviderKeys, networkOptimizationStore.deselectedExistingProviderKeys]);

    useEffect(() => setScrollToExistingProvider(existingProviderQueuedForDeletion), [existingProviderQueuedForDeletion]);

    useEffect(() => {
        const controller = new AbortController();
        setLoading(true);
        setError(null);
        const loadData = async () => {
            try {
                await networkOptimizationStore.loadLocalProviders(controller);
                networkOptimizationStore.deselectAllAdditionalProviders();
                networkOptimizationStore.selectAllExistingProviders();
                setLoading(false);
            } catch (err) {
                handleError(err as Error, setError);
            }
        };

        loadData();

        return () => controller.abort();
    }, [networkOptimizationStore.specialtyFilter, networkOptimizationStore.appliedGeographyFilter, networkOptimizationStore.runId, networkOptimizationStore.revisionCount]);

    const tableRows = useMemo(() => localProviders.map(p => ({...p, existing: false})).concat(existingProviders.map(p => ({...p, existing: true}))), [localProviders, existingProviders]);

    if (error)
        return <div className='error'>{error}</div>;

    if (loading)
        return <DataTableSkeleton headers={[{key: ' ', header: ' '}, ...headers]} rowCount={3} columnCount={headers.length + 1} compact showToolbar={false} />;

    return <div className='additional-providers-table h-full'>
        {tableRows.length === 0 && <p>No providers found</p>}
        {!!tableRows.length && <DataTable rows={tableRows} headers={headers} size='sm' isSortable stickyHeader>
            {({
                rows,
                headers,
                getHeaderProps,
                getRowProps,
                getTableProps,
                getTableContainerProps,
                getToolbarProps,
                getBatchActionProps,
            }) => <TableContainer title={<div className='font-semibold mt-[-1.25rem] mb-[-.75rem]'>Available Providers/Facilities{networkOptimizationStore.specialtyFilter && <> - {networkOptimizationStore.specialtyFilter}</>}</div>} {...getTableContainerProps()}>
                <TableToolbar {...getToolbarProps()} aria-label="data table toolbar">
                    <TableBatchActions {...getBatchActionProps()} shouldShowBatchActions={!!selection.length || !!deselection.length} translateWithId={
                        (id) => {
                            if (id === 'carbon.table.batch.cancel') return 'Cancel';
                            return `${selection.length ? `${selection.length} provider${selection.length > 1 ? 's' : ''} to add${deselection.length ? ', ' : ''}` : ''}${deselection.length ? `${deselection.length} provider${deselection.length > 1 ? 's' : ''} to remove` : ''}`;
                        }
                    } totalSelected={selection.length + deselection.length} onCancel={() => {networkOptimizationStore.deselectAllAdditionalProviders();networkOptimizationStore.selectAllExistingProviders();}}>
                        <UpdateRosterButton
                            onClick={async () => {
                                try {
                                    await networkOptimizationStore.updateRoster(new AbortController());
                                } catch (err) {
                                    setError('Failed to update roster. Please try again later.');
                                }
                            }}
                        />
                    </TableBatchActions>
                </TableToolbar>
                <Table {...getTableProps()} aria-label="sample table" className='!overflow-y-hidden' >
                    <TableHead className='pr-4'>
                        <TableRow>
                            <TableHeader className='select-col' key='select' >
                                &nbsp;
                            </TableHeader>
                            {headers.map((header, i) => {
                                let headerProps = getHeaderProps({header});
                                let headerOnClick = headerProps.onClick as any;
                                return <TableHeader {...headerProps} key={i} onClick={headerOnClick}>
                                    {header.header}
                                </TableHeader>;
                            })}
                        </TableRow>
                    </TableHead>
                    <TableBody className='h-[calc(100%-2rem)] !overflow-y-hidden'>
                        <div className='h-full'>
                            <AutoSizer>
                                {({height, width}) => (
                                    <List ref={listRef} height={height} rowHeight={({index}) => {
                                        const cellWidth = ((width - 52) / headers.length) - 32; //cell x padding
                                        const maxWidth = Math.max(...rows[index].cells.map(c => getTextWidth(c.value ? c.value.toString() : '' + '      ', 18)));
                                        const lines = Math.ceil(maxWidth / cellWidth);
                                        return (lines * 18) + 6 + 7 + 1; //line height and cell y padding
                                    }} rowCount={rows.length} width={width} overscanRowCount={10} rowRenderer={({index: i, style, key}) => {
                                        const row = rows[i];
                                        const rowProps = getRowProps({row});
                                        const existingProvider = existingProviders.some(p => p.id === row.id);
                                        const checked = !existingProvider ? selection.includes(row.id) : !deselection.includes(row.id);
                                        return <TableRow {...rowProps} key={key} style={style} className={checked ? 'checked-row' : ''}>
                                            <TableCell className='select-col' key={`select-${row.id}`}>
                                                <Checkbox id={`check-${row.id}`} labelText='' checked={checked} onChange={(_, {checked}) => {
                                                    let operation = checked ? networkOptimizationStore.selectAdditionalProviders : networkOptimizationStore.deselectAdditionalProviders;
                                                    if (existingProvider) 
                                                        operation = checked ? networkOptimizationStore.selectExistingProviders : networkOptimizationStore.deselectExistingProviders;
                                                    operation.call(networkOptimizationStore, [row.id]);
                                                }} />
                                            </TableCell>
                                            {row.cells.map(cell => <TableCell key={cell.id}>{cell.value && ['marginal_impact_time', 'marginal_impact_distance'].some(h => cell.id.split(':')[1] === h) ? new PercentFormatter(.01, null).formatValue(cell.value) : cell.value}</TableCell>)}
                                        </TableRow>;
                                    }}
                                    scrollToIndex={rows.findIndex(r => r.id === (scrollToExistingProvider ? scrollToExistingProvider : selection[selection.length - 1]))}
                                    scrollToAlignment="start"
                                    />)
                                }
                            </AutoSizer>
                        </div>
                    </TableBody>
                </Table>
            </TableContainer>}
        </DataTable>}
    </div>;
}

export default observer(AdditionalProvidersTable);