import auth from '../../lib/auth';
import Popup from '../popup';
import {ReactElement, RefObject} from 'react';
import checkAccess, {SiteFeature} from '../../lib/check-access';
import {Link} from 'react-router-dom';
import {Friendship, Login, Logout, Password, UserSettings, VolumeFileStorage} from '@carbon/icons-react';
import {useMsal} from '@azure/msal-react';

type Props = {
    popupRef: RefObject<HTMLButtonElement>
}
export default function ProfileMenu(props: Props) {
    const {popupRef} = props;
    const {instance} = useMsal();

    return <Popup ariaLabelledBy='user-menu' targetRef={popupRef} customWidth={320} disableMaxHeight transparentBackground>
        <div className="profile-menu">
            {!auth.principal && <ProfileItem name="Log In" icon={<Login />} link="/login"/>}
            {auth.principal && <div className="flex flex-col space-y-4">
                <span>My Account</span>
                <hr className="border-gray-400"/>
                <div className="text-white break-words">
                    <div className="text-xl">{auth.principal.name}</div>
                    <div>{auth.principal.email}</div>
                </div>
                <hr className="border-gray-400"/>
                <ProfileItem name="My Files" link="/user/user-downloads" icon={<VolumeFileStorage />} />
                <ProfileItem name="Account Settings" link="/user/account" icon={<UserSettings />}/>
                {checkAccess(SiteFeature.Admin) && <ProfileItem name="Site Administration" link="/site-admin" icon={<Password />}/>}
                {auth.isProxied() && <ProfileItem name="Return to Account" onClick={auth.unProxy} icon={<Friendship />}/>}
                <hr className="border-gray-400"/>
                <ProfileItem name="Log Out" onClick={() => auth.logOut(instance)} textColor="text-blue-200" icon={<Logout />}/>
            </div>}
        </div>
    </Popup>;
}


type ProfileItemProps = {
    name: string,
    icon: ReactElement,
    link?: string,
    onClick?: () => void
    textColor?: string
}
function ProfileItem(props: ProfileItemProps) {
    const {name, icon, link, onClick, textColor} = props;

    if (link)
        return <Link to={link} className={`${textColor || 'text-white'} flex items-center`} role='menuitem'>
            {icon}
            <span className="ml-2">{name}</span>
        </Link>;
    else if (onClick)
        return <div onClick={onClick} className={`${textColor || 'text-white'} hover:cursor-pointer hover:underline flex items-center`} role='menuitem'>
            {icon}
            <span className="ml-2">{name}</span>
        </div>;
    else
        return null;
}
