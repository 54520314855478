import {ContentSwitcher, DataTable, Pagination, Table, TableBody, TableCell, TableContainer, Switch, TableHead, TableHeader, TableRow, DataTableSkeleton} from '@carbon/react';
import React, {useEffect, useMemo, useState} from 'react';
import {networkOptimization} from '../../../lib/api';

interface Provider {
    id: string;
    providerFacilityType: string;
    npi: string;
    originalVsAdded: 'O' | 'A';
    ssacd: string;
    specialtyCode: string;
    fullName: string;
    streetAddress: string;
    city: string;
    state: string;
    zipCode: string;
    acceptsNewPatients: 'Y' | 'N';
    specificExceptionToWrittenAgreements: 'Y' | 'N';
    letterOfIntent: 'Y' | 'N';
}

const headers = [
    {key: 'originalVsAdded', header: 'Original vs. Added'},
    {key: 'ssacd', header: 'SSA State/County Code'},
    {key: 'specialtyCode', header: 'Specialty Code'},
    {key: 'npi', header: 'National Provider Identifier (NPI) Number'},
    {key: 'fullName', header: 'Name of Physician or Mid-Level Practitioner'},
    {key: 'streetAddress', header: 'Street Address'},
    {key: 'city', header: 'City'},
    {key: 'state', header: 'State'},
    {key: 'zipCode', header: 'ZIP Code'},
    {key: 'acceptsNewPatients', header: 'If PCP, Accepts New Patients? (Y/N)'},
    {key: 'specificExceptionToWrittenAgreements', header: 'RPPO-Specific Exception to Written Agreements (Y/N)'},
    {key: 'letterOfIntent', header: 'Letter of Intent? (Y/N) Only Applicable for MA Applicants'},
];

const PAGE_SIZE = 16;

const pluralize = (word: string, count: number) => count === 1 ? word : word === 'Facility' ? `Facilities` : `${word}s`;

const RosterTable = ({rosterNumber}: {rosterNumber: number}) => {
    const [providers, setProviders] = useState<Provider[]>([]);
    const [loading, setLoading] = useState(true);
    const [page, setPage] = useState(0);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [error, setError] = useState<string | null>(null);  
    const [viewType, setViewType] = useState<string>('Provider');

    useEffect(() => {
        const controller = new AbortController();
        setLoading(true);
        setError(null); 
        networkOptimization.fetchProviderRoster(rosterNumber, controller.signal)
            .then((providers) => {
                setProviders(providers.map((p: any, i: number) => ({...p, id: `${p.npi}${i}`})));
                setLoading(false);
            })
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            .catch((_err) => {
                setLoading(false);
                setError('Failed to load provider data. Please try again later.'); 
            });

        return () => controller.abort();
    }, [rosterNumber]);

   
    const rows = useMemo(() => providers.filter(r => r.providerFacilityType === viewType), [viewType, providers]);

    if (loading) 
        return <DataTableSkeleton headers={headers} rowCount={PAGE_SIZE - 4} columnCount={headers.length} compact showToolbar={false} />;

    return <DataTable rows={rows} headers={headers} isSortable size='sm'>
        {({
            rows,
            headers,
            getHeaderProps,
            getRowProps,
            getTableProps,
            getTableContainerProps
        }) => <TableContainer title={<div className='mt-[-1.25rem] mb-[-.75rem]'>Roster</div>} 
            description={<>
                <div className='mb-4'>Select providers/facilities to include in this roster</div>
                <ContentSwitcher className='mb-4 !justify-normal' onChange={({name}) => setViewType(name!.toString()) } selectedIndex={0} size={'sm'}>
                    <Switch name='Provider' text='Provider Roster' />
                    <Switch name='Facility' text='Facility Roster' />
                </ContentSwitcher>
            </>} {...getTableContainerProps()} className='overflow-none'>
            
            <Table {...getTableProps()} className='overflow-hidden'>
                <TableHead>
                    <TableRow>
                        {headers.map((header, i) => {
                            let headerProps = getHeaderProps({header});
                            let headerOnClick = headerProps.onClick as any;
                            return <TableHeader {...headerProps} key={i} onClick={headerOnClick}>
                                {header.header}
                            </TableHeader>;
                        })}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {rows.slice(page * PAGE_SIZE, (page + 1) * PAGE_SIZE).map(row => {
                        const rowProps = getRowProps({row});
                        return <TableRow {...rowProps} key={row.id} >
                            {row.cells.map(cell => <TableCell key={cell.id}>{cell.value}</TableCell>)}
                        </TableRow>;
                    })}
                </TableBody>
            </Table>
            <Pagination 
                totalItems={rows.length} 
                pageSize={PAGE_SIZE} 
                pageSizes={[PAGE_SIZE]} 
                pageSizeInputDisabled 
                page={page + 1} 
                isLastPage={page === Math.floor(rows.length / PAGE_SIZE)} 
                onChange={({page: newPage}) => setPage(newPage - 1)}
                itemsPerPageText={`${pluralize(viewType, PAGE_SIZE)} per page:`}
                itemRangeText={(min, max, total) => `${min}-${max} of ${total} ${pluralize(viewType, total).toLocaleLowerCase()}`} />
        </TableContainer>}
    </DataTable>;
    
};

export default RosterTable;