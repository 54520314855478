import {useContext, useEffect} from 'react';
import {Outlet, useNavigate, useParams} from 'react-router-dom';
import {NetworkContext} from '../lib/context';
import {networkOptimization} from '../../../lib/api';

type RosterIDParam = {
    rosterId: string
}

export function RosterIDWrapper() {
    const {rosterId} = useParams<RosterIDParam>();
    const networkOptimizationStore = useContext(NetworkContext);
    const navigate = useNavigate();

    useEffect(() => {
        if (!Number(rosterId))
            return;
        const abortController = new AbortController();
        networkOptimization.getUserProviderRoster(Number(rosterId), abortController.signal).then((response) => {
            networkOptimizationStore.rosterFileName = response.rosterName;
        });

        return () => {
            abortController.abort();
        };
    }, [rosterId, navigate]);

    if (!Number(rosterId))
        navigate('/network-optimization/my-files');

    networkOptimizationStore.runId = Number(rosterId);

    return <Outlet />;
}