import {AuthenticationResult, EventType, PublicClientApplication} from '@azure/msal-browser';
import {msalConfig} from './entra-auth-config';
import {ReactNode} from 'react';
import {MsalProvider} from '@azure/msal-react';

type Props = {
    children: ReactNode
}

const msalInstance = new PublicClientApplication(msalConfig);

const EntraAuthProvider = ({children}: Props) => {
    /**
     * MSAL should be instantiated outside of the component tree to prevent it from being re-instantiated on re-renders.
     * For more, visit: https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/getting-started.md
     */
    // Default to using the first account if no account is active on page load
    if (!msalInstance.getActiveAccount() && msalInstance.getAllAccounts().length > 0) {
        // Account selection logic is app dependent. Adjust as needed for different use cases.
        msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0]);
    }

    // Listen for sign-in event and set active account
    msalInstance.addEventCallback((event) => {
        const authenticationResult = event.payload as AuthenticationResult;
        if (authenticationResult && event.eventType === EventType.LOGIN_SUCCESS) {
            const account = authenticationResult.account;
            if (account)
                msalInstance.setActiveAccount(account);
        }
    });

    return <MsalProvider instance={msalInstance}>
        {children}
    </MsalProvider>;
};

function useEntraAuthProvider() {
    return {EntraAuthProvider};
}

export {
    msalInstance,
    EntraAuthProvider,
    useEntraAuthProvider
};