import {GraphVersions, QuantumGraph} from 'quantum-graph';
import {compressedData, dataRow, dataRows, dataValue} from './data-types';
import {quantum} from './api';
import Variable, {adjustMetadata} from './metadata';
import createGraphHandler from './graph-tools/graph-handler/create';
import quantumAccelerator from './quantum-accelerator';
import {getDefaultDataDefRepo} from './data-defs';
import {GeoJSON} from 'geojson';


export function decompressData(data: compressedData | dataRows): dataRows {
    if (data instanceof Array)
        return data as dataRows;

    const newData: dataRows = [];
    data.data.forEach((row: Array<dataValue>) => {
        const item: dataRow = {};
        data.fields.forEach((f, i) => item[f] = row[i]);
        newData.push(item);
    });
    return newData;
}

export default async function fetchData(graph: QuantumGraph, signal: AbortSignal): Promise<dataRows> {
    let data = await executeGraphQuery(graph, signal);

    return decompressData(data);
}

export async function fetchDataWithUnAuthorizedVariables(graph: QuantumGraph, signal: AbortSignal): Promise<{data: dataRows, unAuthorizedVariables: string[]}> {
    let data = await executeGraphQuery(graph, signal);

    return {data: decompressData(data), unAuthorizedVariables: data.unauthorizedVariables};
}

async function executeGraphQuery(graph: QuantumGraph, signal: AbortSignal) {
    const isV2Graph = graph.getVersion() === GraphVersions.V2;
    let data;
    if (quantumAccelerator.isIndexedDBSupported() && isV2Graph)
        data = await quantumAccelerator.getQuery(graph);
    if (!data) {
        const obj = graph.serialize();
        data = await quantum.execute(obj, signal, false);
        if (data.error)
            throw new Error(data.message);
        if (isV2Graph && process.env.NODE_ENV !== 'test')
            quantumAccelerator.addQuery(graph, data).catch(e => console.error(e));
    }
    return data;
}

export async function fetchMetadata(graph: QuantumGraph, signal: AbortSignal): Promise<Array<Variable>> {
    const isV2Graph = graph.getVersion() === GraphVersions.V2;
    let data;
    if (quantumAccelerator.isIndexedDBSupported() && isV2Graph)
        data = await quantumAccelerator.getMetadata(graph);
    if (!data) {
        const obj = graph.serialize();
        data = await quantum.metadata(obj, signal);
        if (isV2Graph)
            quantumAccelerator.addMetadata(graph, data).catch(e => console.error(e));
    }
    const metadata = data.map(Variable.from);
    adjustMetadata(metadata);
    return metadata;
}

export function isEqual(o1: dataRow, o2: dataRow) {
    if (typeof o1 !== 'object' || typeof o2 !== 'object') {
        return false; // we compare objects only!
    }
    // when one object has more attributes than the other - they can't be eq
    if (Object.keys(o1).length !== Object.keys(o2).length) {
        return false;
    }
    for (const k of Object.keys(o1)) {
        if (o1[k] !== o2[k]) {
            return false;
        }
    }
    return true;
}

export function usePTSynapseEnvironment(graph: QuantumGraph) {
    const datasets = createGraphHandler(graph, getDefaultDataDefRepo()).getDatasets();
    return !!datasets.length && datasets.every(ds => ds.startsWith('pt-'));
}

export function convertDataToGeoJSONPoints(data: dataRows, latField: string, lonField: string): GeoJSON.FeatureCollection<GeoJSON.Point> {
    return {
        type: 'FeatureCollection',
        features: data.filter(r => !!r[lonField] && !!r[latField]).map(row => ({
            type: 'Feature',
            geometry: {
                type: 'Point',
                coordinates: [Number(row[lonField]!), Number(row[latField]!)]
            },
            properties: row
        }))
    };
}
