import BaseMap from '../../../components/base-map/base-map';
import {LayerPropsWithType, Layers} from '../../../components/base-map/layers/types';
import {useContext, useEffect, useMemo, useRef, useState} from 'react';
import {FeatureCollection} from 'geojson';
import mapboxConfig from '../../../insight-container/map/map-libs/mapbox-config';
import {NetworkContext} from '../lib/context';
import {CirclePaint, MapMouseEvent} from 'mapbox-gl';
import {observer} from 'mobx-react';
import bbox from '@turf/bbox';
import {BBox2d} from '@turf/helpers/dist/js/lib/geojson';
import Spinner from '../../../components/spinner';
import handleError from '../../../lib/error';
import {Checkbox, IconButton} from '@carbon/react';
import {CheckboxCheckedFilled, Group, Hospital, Icon, LocationFilled, Maximize} from '@carbon/icons-react';
import {convertDataToGeoJSONPoints} from '../../../lib/data';
import {PercentFormatter} from '../../../lib/formatter';
import './network-map.scss';
import {UpdateRosterButton} from './additional-providers-table';

const INVALID_COLOR = 'rgba(205, 77, 81, 0.6)';
const VALID_COLOR = 'rgba(0, 120, 212, 0.6)';
const NULL_COLOR = 'rgba(79, 93, 117, 0.6)';
const PROVIDER_COLOR = 'rgba(255, 222, 102, 1)';
const ADDITIONAL_PROVIDER_COLOR = 'rgba(150, 215, 237, 1)';
const SELECTED_PROVIDER_COLOR = 'rgba(214, 183, 255, 1)';
const PROPERTY_TO_MAP = 'adequacy';
const CONTINENTAL_US_BOUNDS = [-124.7844079, 24.7433195, -66.9513812, 49.3457868];

const DEFAULT_LAYER_STYLE: CirclePaint = {
    'circle-radius': .25, 
    'circle-color': ['match', ['get', PROPERTY_TO_MAP], '1', VALID_COLOR, '0', INVALID_COLOR, NULL_COLOR ] //['interpolate',['linear'],['get', PROPERTY_TO_MAP],0,INVALID_COLOR,1,VALID_COLOR]
};

const INTERACTIVE_MAP_LAYER_IDS = ['layer-2', 'layer-3', 'layer-4'];

function createBaseLayers(patientData: FeatureCollection, providerData: FeatureCollection): {name: string, icon: typeof Icon, layer: LayerPropsWithType}[] {
    return [{name: 'Patients', icon: Group, layer: {
        id: `layer-1`,
        data: patientData,
        layerType: Layers.circle,
        paint: DEFAULT_LAYER_STYLE,
        cluster: false
    }},
    {name: 'Providers', icon: Hospital, layer: {
        id: `layer-2`,
        data: providerData,
        layerType: Layers.point,
        paint: {'icon-color': PROVIDER_COLOR},
        cluster: false
    }}];
}

function createAdditionalProviderLayers(additionalProviders: FeatureCollection, selectedAdditionalProviders: FeatureCollection): {name: string, icon: typeof Icon, layer: LayerPropsWithType}[] {
    return [{name: 'Additional Providers', icon: Hospital, layer: {id: `layer-3`,
        data: additionalProviders,
        layerType: Layers.point,
        paint: {'icon-color': ADDITIONAL_PROVIDER_COLOR},
        cluster: false
    }},
    {name: 'Selected Additional Providers', icon: Hospital, layer: {id: `layer-4`,
        data: selectedAdditionalProviders,
        layerType: Layers.point,
        paint: {'icon-color': SELECTED_PROVIDER_COLOR},
        layout: {'icon-image': 'check-pin'},
        cluster: false
    }}];
}

function NetworkMap() {
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string>('');
    const [popupOptions, setPopupOptions] = useState<any>();
    const [disabledLayers, setDisabledLayers] = useState<string[]>([]);
    const networkStore = useContext(NetworkContext);
    const divRef = useRef<HTMLDivElement>(null);
    const isFullscreen = useMemo(() => !!document.fullscreenElement, [document.fullscreenElement]);

    const requestFullScreen = () => {
        const element = divRef.current;
        if (!element) return;

        if (document.fullscreenElement) {
            document.exitFullscreen();
        } else if (element.requestFullscreen) {
            element.requestFullscreen();
        }
    };

    useEffect(() => {
        const controller = new AbortController();
        setLoading(true);
        setError('');
        networkStore.loadMapGeoJson(controller)
            .then(() => {
                if (networkStore.patientGeoJson && networkStore.providerGeoJson)
                    setLoading(false);
            })
            .catch((error) => handleError(error, setError));

        return () => controller.abort();
    }, [networkStore.appliedGeographyFilter, networkStore.specialtyFilter, networkStore.runId, networkStore.revisionCount]);

    function onHoverCB(feature: Array<any>) {
        if (!feature || !feature.length) {
            setPopupOptions(null);
            return;
        }
        const fProps = feature[0].properties;
        const popupElement = <ul className='text-background-gray-900'>
            <li><strong>{fProps.full_name}</strong></li>
            <li>NPI: {fProps.npi}</li>
            <li>Zipcode: {fProps.practice_location_address_postal_code}</li>
            {fProps.marginal_impact_time && <li>Impact Time: {new PercentFormatter(.01, null).formatValue(fProps.marginal_impact_time)}</li>}
            {fProps.marginal_impact_distance && <li>Impact Distance: {new PercentFormatter(.01, null).formatValue(fProps.marginal_impact_distance)}</li>}
        </ul>;
        setPopupOptions({name: popupElement, latitude: fProps.latitude, longitude: fProps.longitude});
    }

    function onClickCB(event: MapMouseEvent) {
        const features = event.features || [];

        if (features && features.length === 0) {
            return;
        }
        const feature = features[0];
        const key = feature.properties!.id || feature.properties!['provider_address_fact_key'];

        if (feature.source === 'layer-2') {
            networkStore.existingProviderQueuedForDeletion = key;
        } else if (feature.source === 'layer-3') {
            networkStore.existingProviderQueuedForDeletion = null;
            if (networkStore.selectedAdditionalProviderKeys.includes(key)) {
                networkStore.deselectAdditionalProviders([key]);
            } else if (networkStore.deselectedExistingProviderKeys.includes(key)) {
                networkStore.selectExistingProviders([key]);
            } else {
                networkStore.selectAdditionalProviders([key]);
            }
        }
    }

    function setLayerEnabled(layerId: string, checked: boolean) {
        setDisabledLayers(checked ? disabledLayers.filter(l => l !== layerId) : disabledLayers.concat(layerId));
    }

    const bounds = useMemo(() => networkStore.providerGeoJson ? bbox(networkStore.providerGeoJson) : CONTINENTAL_US_BOUNDS, [networkStore.providerGeoJson]);
    const allLayers = useMemo(() => {
        let newLayers: {name: string, icon: typeof Icon, layer: LayerPropsWithType}[] = [];

        if (!(networkStore.patientGeoJson && networkStore.providerGeoJson))
            return newLayers;

        newLayers = newLayers.concat(createBaseLayers(networkStore.patientGeoJson, networkStore.providerGeoJson));

        if (networkStore.localProviders.length)
            newLayers = newLayers.concat(createAdditionalProviderLayers(convertDataToGeoJSONPoints(networkStore.nonSelectedAdditionalProviders, 'latitude', 'longitude'), convertDataToGeoJSONPoints(networkStore.selectedAdditionalProviders, 'latitude', 'longitude')));

        return newLayers;
    }, [networkStore.patientGeoJson, networkStore.providerGeoJson, networkStore.localProviders, networkStore.selectedAdditionalProviders]);
    const layers = useMemo(() => allLayers.filter(l => !disabledLayers.includes(l.layer.id.toString())).map(l => l.layer), [allLayers, disabledLayers]);
    const interactiveMapLayerIds = useMemo(() => [...layers.filter(l => INTERACTIVE_MAP_LAYER_IDS.includes(l.id as string)).map(l => l.id as string)], [layers]);

    return <div className="cds--g90 w-full h-full relative flex justify-stretch" ref={divRef} >
        {loading && <div className="flex items-center absolute w-full h-full bg-background-gray-300 bg-opacity-20 justify-center z-[150]"><Spinner /> <span className="ml-2 text-xl font-semibold text-background-gray-300">Loading Map Data...</span></div>}
        {error && <div className="text-red-500">{error}</div>}
        {!error &&
            <>
                <BaseMap style={{width: '100%', height: '100%'}}
                    layers={layers}
                    viewState={{bounds: bounds as BBox2d, fitBoundsOptions: {padding: 20}}}
                    mapStyle={mapboxConfig.darkStyle} 
                    interactiveLayers={interactiveMapLayerIds}
                    popupOptions={popupOptions}
                    onHoverCB={onHoverCB}
                    onClickCB={onClickCB} />
                <div className="bg-background-gray-700 text-background-gray-100 absolute top-2 right-2 p-2 flex flex-col gap-1 z-[149]">
                    <div className='text-base font-semibold pb-1'>Layers</div>
                    {allLayers.map((l, i) => 
                        <div key={i} >
                            <Checkbox className='!text-xs' checked={!disabledLayers.includes(l.layer.id.toString())} id={i.toString()} labelText={l.name} onChange={(_, {checked}) => setLayerEnabled(l.layer.id.toString(), checked)} />
                        </div>)}
                </div>
                <div className="flex flex-row items-center absolute bottom-2 left-0 right-0 m-auto w-[fit-content] max-w-[calc(100%-1rem)] bg-background-gray-700 text-background-gray-100 justify-around z-[149] p-2 gap-3 flex-wrap">
                    {networkStore.specialtyFilter ? <>
                        <div className='inline-flex text-xs gap-2 bg-opacity-100'>
                            <svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg" fill={VALID_COLOR} className='w-4' >
                                <circle cx="50" cy="50" r="50" />
                            </svg>
                            <span className='text-nowrap'>In Compliance</span>
                        </div>
                        <div className='inline-flex text-xs gap-2 bg-opacity-100'>
                            <svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg" fill={INVALID_COLOR} className='w-4' >
                                <circle cx="50" cy="50" r="50" />
                            </svg>
                            <span className='text-nowrap'>Not In Compliance</span>
                        </div>
                    </> : <div className='inline-flex text-xs gap-2'>
                        <svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg" fill={NULL_COLOR} className='w-4' >
                            <circle cx="50" cy="50" r="50" />
                        </svg>
                        <span className='text-nowrap'>Patients</span>
                    </div>}
                    <div className='inline-flex text-xs gap-2'>
                        <LocationFilled style={{'color': PROVIDER_COLOR}} />
                        <span className='text-nowrap'>Current Providers</span>
                    </div>
                    {!!networkStore.localProviders.length && <div className='inline-flex text-xs gap-2'>
                        <LocationFilled style={{'color': ADDITIONAL_PROVIDER_COLOR}} />
                        <span className='text-nowrap'>Additional Providers</span>
                    </div>}
                    {!!networkStore.selectedAdditionalProviders.length && <div className='inline-flex text-xs gap-2'>
                        <CheckboxCheckedFilled style={{'color': SELECTED_PROVIDER_COLOR}} />
                        <span className='text-nowrap'>Selected Additional Providers</span>
                    </div>}
                </div>
                <div className="absolute bottom-2 right-2 z-[149] flex flex-col items-end gap-2">
                    {isFullscreen && networkStore.selectedAdditionalProviders.length && <div className='bg-background-gray-700 text-background-gray-100 flex flex-col gap-1 p-2'>
                        <div className='text-base font-semibold pb-1'>Selected Providers</div>
                        {networkStore.selectedAdditionalProviders.map((p, i) => <div key={i}>{p.full_name}</div>)}
                        <UpdateRosterButton onClick={() => networkStore.updateRoster(new AbortController())} />
                    </div>}
                    <IconButton kind='secondary' align='top-end' onClick={requestFullScreen} label='Full Screen'><Maximize /></IconButton>
                </div>
            </>}
    </div>;
}

export default observer(NetworkMap);