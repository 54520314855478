import {DataTable, Table, TableBody, TableCell, TableContainer, TableHead, TableHeader, TableRow, Button, DataTableSkeleton} from '@carbon/react';
import React, {useEffect, useState} from 'react';
import {ChevronDown} from '@carbon/icons-react'; 
import Tabs from '../components/tabs/tabs';
import {RadioButton} from '@carbon/react';
import {Link, useNavigate} from 'react-router-dom';
import {networkOptimization} from '../../../lib/api';

function MyFiles() {
    const navigate = useNavigate();
    const [rows, setRows] = useState<any[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [error, setError] = useState<string | null>(null);
    const [expandedRowId, setExpandedRowId] = useState(null);
    const [selectedRowId, setSelectedRowId] = useState(null); 

    useEffect(() => {
        const abortController = new AbortController();
        const loadData = async () => {
            setError(null);
            setLoading(true); 
            try {
                await networkOptimization.listProviderRosters(abortController.signal).then((response) => {
                    setRows(response);
                });
            } catch (err) {
                setError('Failed to My Files data. Please try again later.');
            } finally {
                setLoading(false); 
            }
        };
        loadData();
        return () => abortController.abort();
    }, []);

    const headers = [
        {key: 'expand', header: ''},
        {key: 'rosterName', header: 'File Name'},
        {key: 'dataValidation', header: 'Data Validation'},
        {key: 'lastUpdated', header: 'Last Updated'},
        {key: 'actions', header: ''},
    ];

    const tabs = [
        {
            label: 'Provider/Facility Locations',
            content: <p></p>, 
        }
    ];

    const toggleExpand = (id: any) => {
        setExpandedRowId(expandedRowId === id ? null : id);
    };

    const handleRadioChange = (id: any) => {
        setSelectedRowId(id); 
    };

    return (
        <div className="my-files-page bg-white shadow-lg h-[calc(100vh-11rem+2px)] p-4 pl-12 w-full overflow-hidden">
            <div className="flex items-center justify-between bg-gray-100 p-4 rounded mb-4">
                <div className="flex flex-col justify-center">
                    <h3 className="text-xl font-bold m-0">My Files</h3>
                    <p className="text-sm text-gray-600 m-0">Choose to upload a new file or browse saved files</p>
                </div>
                <button className="bg-blue-500 text-white font-semibold py-2 px-4 rounded ml-auto">
                    Upload New File
                </button>
            </div>

            <div className="grid grid-cols-6 gap-4">
                <div className="col-span-6 bg-white shadow-lg rounded p-2">
                    <Tabs tabs={tabs} />

                    <div className="mt-4">
                        <div className="flex justify-end mb-2">
                            <Button kind="primary" disabled={!selectedRowId} onClick={() => navigate(`../${selectedRowId}/time-distance-access`)} >Open File</Button>
                        </div>
                        {loading ? <DataTableSkeleton 
                            headers={headers} 
                            columnCount={headers.length}
                            rowCount={12}
                            showHeader={false}
                            showToolbar={false}
                        /> : <TableContainer>
                            <DataTable rows={rows} headers={headers}>
                                {({headers}) => (
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                {headers.map(header => (
                                                    <TableHeader key={header.key}>
                                                        <div className="w-full text-left">{header.header}</div>
                                                    </TableHeader>
                                                ))}
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {rows.map(row => (
                                                <React.Fragment key={row.rosterId}>
                                                    <TableRow>
                                                        <TableCell>
                                                            <div className="flex items-center">
                                                                <RadioButton
                                                                    id={`radio-${row.rosterId}`}
                                                                    name="file"
                                                                    labelText=""
                                                                    value={row.id}
                                                                    checked={selectedRowId === row.id} 
                                                                    onChange={() => handleRadioChange(row.id)} 
                                                                />
                                                                <button onClick={() => toggleExpand(row.id)} className="ml-2 flex items-center">
                                                                    <ChevronDown className={`transform transition-transform ${expandedRowId === row.rosterId ? 'rotate-180' : ''}`} />
                                                                </button>
                                                            </div>
                                                        </TableCell>
                                                        <TableCell><Link to={`../${row.rosterId}/time-distance-access`}>{row.rosterName}</Link></TableCell>
                                                        <TableCell>{row.dataValidation}</TableCell>
                                                        <TableCell>{row.lastUpdated}</TableCell>
                                                        <TableCell>
                                                            <button className="text-blue-500">Edit/Delete</button>
                                                        </TableCell>
                                                    </TableRow>
                                                    {expandedRowId === row.rosterId && (
                                                        <TableRow>
                                                            <TableCell colSpan={5} className="bg-gray-100">
                                                                <p>Details for {row.rosterName}</p>
                                                            </TableCell>
                                                        </TableRow>
                                                    )}
                                                </React.Fragment>
                                            ))}
                                        </TableBody>
                                    </Table>
                                )}
                            </DataTable>
                        </TableContainer>
                        }
                    </div>
                </div>
            </div>
        </div>
    );
}

export default MyFiles;
