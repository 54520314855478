import {useLocation, Navigate} from 'react-router-dom';
import auth from '../lib/auth';
import checkAccess, {SiteFeature} from '../lib/check-access';
import React from 'react';
import {AuthenticatedTemplate, useMsal} from '@azure/msal-react';

const thirtyMinutes = 30 * 60;

type Props = {
    children: JSX.Element,
    siteFeature?: SiteFeature,
}

export default function AuthWall(props: Props): JSX.Element | null {
    const location = useLocation();
    const {instance} = useMsal();

    if (auth.principal) {
        const now = new Date().getTime() / 1000;
        if (auth.principal.exp - thirtyMinutes < now)
            auth.refresh();
        if (auth.principal.exp > now) {
            if (props.siteFeature && !checkAccess(props.siteFeature)) {
                return <Navigate replace to='/' />;
            }
            if (instance.getActiveAccount()) {
                return <AuthenticatedTemplate>
                    {props.children}
                </AuthenticatedTemplate>;
            }
            return props.children;
        }
    }

    return <Navigate replace to="/login" state={{dest: location.pathname}} />;
}