import React, {useRef, useState} from 'react';
import logo from '../images/logo.svg';
import logoFull from '../images/logo-full.svg';
import logoFullLight from '../images/logo-full-light.svg';
import Popup from '../popup';
import auth from '../../lib/auth';
import MenuItem from '../popup/menu-item';
import Button from '../button';
import {Link} from 'react-router-dom';
import checkAccess, {SiteFeature} from '../../lib/check-access';
import classNames from '../../lib/class-names';
import {useMsal} from '@azure/msal-react';

export type NavbarProps = {
    light?: boolean
    float?: boolean
    className?: string
}

export default function Navbar(props: NavbarProps) {
    const [menuOpen, setMenuOpen] = useState(false);
    const navMenuRef = useRef<HTMLButtonElement>(null);
    const {instance} = useMsal();

    const classes = classNames('transition', props.className, {
        'fixed w-full top-0 z-10': props.float,
        'bg-white shadow': !props.float,
    });
    const buttonStyle = props.float ? 'shade' : 'light';

    return (
        <nav className={classes}>
            <div className='max-w-7xl mx-auto px-2 sm:px-6 lg:px-8'>
                <div className='relative flex items-center justify-between h-16'>
                    <div className='absolute inset-y-0 left-0 flex items-center sm:hidden'>
                        {/* Mobile menu button*/}
                        <button
                            className='inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white'
                            aria-expanded='false'
                            onClick={() => setMenuOpen(!menuOpen)}
                        >
                            <span className='sr-only'>Open main menu</span>

                            <svg
                                className={(menuOpen ? 'hidden' : 'block') + ' h-6 w-6'}
                                xmlns='http://www.w3.org/2000/svg'
                                fill='none'
                                viewBox='0 0 24 24'
                                stroke='currentColor'
                                aria-hidden='true'
                            >
                                <path
                                    strokeLinecap='round'
                                    strokeLinejoin='round'
                                    strokeWidth='2'
                                    d='M4 6h16M4 12h16M4 18h16'
                                />
                            </svg>

                            <svg
                                className={(menuOpen ? 'block' : 'hidden') + ' h-6 w-6'}
                                xmlns='http://www.w3.org/2000/svg'
                                fill='none'
                                viewBox='0 0 24 24'
                                stroke='currentColor'
                                aria-hidden='true'
                            >
                                <path
                                    strokeLinecap='round'
                                    strokeLinejoin='round'
                                    strokeWidth='2'
                                    d='M6 18L18 6M6 6l12 12'
                                />
                            </svg>
                        </button>
                    </div>
                    <div className='flex-1 flex items-center justify-center sm:items-stretch sm:justify-start'>
                        <div className='flex-shrink-0 flex items-center'>
                            {/*<a href={'//' + process.env.REACT_APP_TI_DOMAIN + '/user/home'}>*/}
                            <Link to='/'>
                                <img
                                    className='block lg:hidden h-8 w-auto'
                                    src={logo}
                                    alt='Torch Insight'
                                />
                                <img
                                    className='hidden lg:block h-8 w-auto'
                                    src={props.light ? logoFullLight : logoFull}
                                    alt='Torch Insight'
                                />
                            </Link>
                        </div>
                        <div className='hidden lg:block flex-grow' />
                        <div className='hidden sm:block sm:ml-6'>
                            {auth.principal && (
                                <div className='flex space-x-4'>
                                    <Link to={'/tools'} className={'button ' + buttonStyle}>
                                        Tools
                                    </Link>
                                    {checkAccess(SiteFeature.HealthcareIndex) && (
                                        <Button
                                            color={buttonStyle}
                                            to='/healthcare-index'
                                            className='block'
                                        >
                                            {/*<span className="mr-2 text-white text-xs bg-green-500 rounded-full uppercase px-2">New</span>*/}
                                            Healthcare Index
                                        </Button>
                                    )}
                                    {/*<a href="#"*/}
                                    {/*   className="px-3 py-2 rounded-md text-sm font-medium text-gray-300 hover:text-white hover:bg-gray-700">Team</a>*/}
                                    {/*<a href="#"*/}
                                    {/*   className="px-3 py-2 rounded-md text-sm font-medium text-gray-300 hover:text-white hover:bg-gray-700">Projects</a>*/}
                                    {/*<a href="#"*/}
                                    {/*   className="px-3 py-2 rounded-md text-sm font-medium text-gray-300 hover:text-white hover:bg-gray-700">Calendar</a>*/}
                                </div>
                            )}
                        </div>
                    </div>
                    <div className='absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0 z-50'>
                        {/* Profile dropdown */}
                        <div className='ml-3 relative'>
                            <div>
                                <button
                                    className='flex text-sm rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white bg-blue-400 text-white h-8 w-8 items-center justify-center'
                                    id='user-menu'
                                    aria-haspopup='true'
                                    ref={navMenuRef}
                                >
                                    <span className='sr-only'>Open user menu</span>
                                    {/*<span className="text-xl">*/}
                                    {/*    <i className="icon-user" />*/}
                                    {/*</span>*/}
                                    <i className='icon-user' />
                                </button>
                            </div>
                            {auth.principal ? (
                                <Popup ariaLabelledBy='user-menu' targetRef={navMenuRef}>
                                    <a href={'/user/account'} className='menu-item' role='menuitem'>
                                        Account
                                    </a>
                                    <a
                                        href={'/user/user-downloads'}
                                        className='menu-item'
                                        role='menuitem'
                                    >
                                        My Downloads
                                    </a>
                                    {checkAccess(SiteFeature.Admin) && (
                                        <a
                                            href={'/site-admin'}
                                            className='menu-item'
                                            role='menuitem'
                                        >
                                            Site Administration
                                        </a>
                                    )}
                                    {auth.isProxied() && (
                                        <button
                                            onClick={auth.unProxy}
                                            className='menu-item w-full text-left'
                                            role='menuitem'
                                        >
                                            Return to Account
                                        </button>
                                    )}
                                    <button
                                        onClick={() => auth.logOut(instance)}
                                        className='menu-item w-full text-left'
                                        role='menuitem'
                                    >
                                        Log Out
                                    </button>
                                </Popup>
                            ) : (
                                <Popup ariaLabelledBy='user-menu' targetRef={navMenuRef}>
                                    <MenuItem to='/login'>Log In</MenuItem>
                                </Popup>
                            )}
                        </div>
                    </div>
                </div>
            </div>

            {/*
          Mobile menu, toggle classes based on menu state.

          Menu open: "block", Menu closed: "hidden"
        */}
            <div className={(menuOpen ? 'block' : 'hidden') + ' sm:hidden'}>
                <div className='px-2 pt-2 pb-3 space-y-1'>
                    <a
                        href={'//' + process.env.REACT_APP_TI_DOMAIN + '/tools'}
                        className={'button ' + buttonStyle}
                    >
                        Tools
                    </a>
                    {checkAccess(SiteFeature.HealthcareIndex) && (
                        <Button color='link' to='/healthcare-index' className='block'>
                            Healthcare Index
                        </Button>
                    )}
                    {/*<a href="#"*/}
                    {/*   className="block px-3 py-2 rounded-md text-base font-medium text-gray-300 hover:text-white hover:bg-gray-700">Team</a>*/}
                    {/*<a href="#"*/}
                    {/*   className="block px-3 py-2 rounded-md text-base font-medium text-gray-300 hover:text-white hover:bg-gray-700">Projects</a>*/}
                    {/*<a href="#"*/}
                    {/*   className="block px-3 py-2 rounded-md text-base font-medium text-gray-300 hover:text-white hover:bg-gray-700">Calendar</a>*/}
                </div>
            </div>
        </nav>
    );
}
