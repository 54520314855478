import Loading from '../../components/loading';
import {useMsal} from '@azure/msal-react';
import {useNavigate} from 'react-router';
import auth from '../../lib/auth';
import {AccountInfo, AuthenticationResult, EventType} from '@azure/msal-browser';
import {useEffect, useState} from 'react';

export default function LogInRedirectPage() {
    const {instance} = useMsal();
    const navigate = useNavigate();
    const [error, setError] = useState<string | null>(null);

    function getTorchTokenFromEntraID(activeAccount: AccountInfo) {
        if (!activeAccount || !activeAccount.idToken)
            return;
        auth.loginWithEntraIDToken(activeAccount.idToken).then(redirectUser);
    }

    function redirectUser() {
        navigate(auth.principal?.landing ? auth.principal.landing : '/');
    }

    useEffect(() => {
        const activeAccount = instance.getActiveAccount();
        if (activeAccount) {
            getTorchTokenFromEntraID(activeAccount);
        } else {
            const callbackId = instance.addEventCallback((event) => {
                if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
                    const account = (event.payload as AuthenticationResult).account;
                    if (account) {
                        instance.setActiveAccount(account);
                        getTorchTokenFromEntraID(account);
                    }
                } else if (event.eventType === EventType.LOGIN_FAILURE) {
                    setError(`Login failed :: ${event.error}`);
                }
            });

            return () => {
                if (callbackId) {
                    instance.removeEventCallback(callbackId);
                }
            };
        }
    }, [instance, navigate]);


    return <div className="w-full h-screen flex justify-center items-center flex-col">
        {error && <div className='text-center text-red-500'>{error}</div>}
        {!error && <Loading large />}
    </div>;
}