import AuthWall from '../../../components/auth-wall';
import './timeDistanceAccessPage.scss';
import RosterTable from '../components/roster-table';
import {Layer, Loading, MenuButton, MenuItem} from '@carbon/react';
import {networkOptimization} from '../../../lib/api';
import {Download} from '@carbon/icons-react';
import download from '../../../lib/download';
import {useContext, useEffect, useState} from 'react';
import {observer} from 'mobx-react';
import {NetworkContext} from '../lib/context';

function LoadingWrapper() {
    return <Loading small withOverlay={false} />;
}

function ProviderRosterPage() {
    const [exportingProviderRoster, setExportingProviderRoster] = useState(false);
    const [exportingFacilityRoster, setExportingFacilityRoster] = useState(false);
    const [exportingZipRoster, setExportingZipRoster] = useState(false);
    const networkOptimizationStore = useContext(NetworkContext);

    useEffect(() => {
        if (exportingProviderRoster) {
            networkOptimization.downloadProviderRoster(networkOptimizationStore.runId!, (new AbortController()).signal).then(response => {
                download(response, 'provider-roster.txt', 'text/plain');
                setExportingProviderRoster(false);
            });
        }
    }, [exportingProviderRoster]);

    useEffect(() => {
        if (exportingFacilityRoster) {
            networkOptimization.downloadFacilityRoster(networkOptimizationStore.runId!, (new AbortController()).signal).then(response => {
                download(response, 'facility-roster.txt', 'text/plain');
                setExportingFacilityRoster(false);
            });
        }
    }, [exportingFacilityRoster]);

    useEffect(() => {
        if (exportingZipRoster) {
            networkOptimization.downloadZipRoster(networkOptimizationStore.runId!, (new AbortController()).signal).then(response => {
                download(response, `combined_roster_${networkOptimizationStore.runId}.zip`, 'application/zip');
                setExportingZipRoster(false);
            });
        }
    }, [exportingZipRoster]);

    return <AuthWall>
        <>
            <div className="time-distance-access py-8 bg-white border-b border-background-gray-200 flex justify-between items-center px-12">
                <h1 className="m-0 text-[28px] font-normal font-inherit leading-none">Roster</h1>
                <MenuButton label='Export' menuAlignment='bottom-end' className="!pr-4 justify-between items-center" kind="primary" >
                    <MenuItem label='Provider Roster' renderIcon={exportingProviderRoster ? LoadingWrapper : Download} onClick={() => setExportingProviderRoster(true)} disabled={exportingProviderRoster} />
                    <MenuItem label='Facility Roster' renderIcon={exportingFacilityRoster ? LoadingWrapper : Download} onClick={() => setExportingFacilityRoster(true)} disabled={exportingFacilityRoster} />
                    <MenuItem label='Combined Roster Zip' renderIcon={exportingZipRoster ? LoadingWrapper : Download} onClick={() => setExportingZipRoster(true)} disabled={exportingZipRoster} />
                </MenuButton>
            </div>
            <Layer className='p-6 pl-12 h-[calc(100vh-11rem+2px)]'>
                <div className="">
                    {networkOptimizationStore.runId && <RosterTable rosterNumber={networkOptimizationStore.runId} />}
                </div>
            </Layer>
        </>
    </AuthWall>;
}

export default observer(ProviderRosterPage);
