import React, {useEffect, useMemo, useRef, useState} from 'react';
import {Navigate, useParams} from 'react-router-dom';
import Template from '../../components/template';
import {determineAPIHost} from '../../lib/api';
import Button from '../../components/button';
import auth from '../../lib/auth';

const DownloadTimeout = 5000;
const path = '/v2/quantum/data/execute';

export default function DownloadingPage() {
    const {id} = useParams<{id: string}>();
    const [allowClick, setAllowClick] = useState(false);
    const [hasDownloaded, setHasDownloaded] = useState(false);
    const buttonRef = useRef<HTMLButtonElement>(null);

    useEffect(() => {
        const timeout = setTimeout(() => setAllowClick(true), DownloadTimeout);
        return () => clearTimeout(timeout);
    }, []);

    useEffect(() => {
        if (!buttonRef.current || hasDownloaded)
            return;
        buttonRef.current.click();
        setHasDownloaded(true);
        localStorage.removeItem('download:' + id);
    }, [buttonRef, hasDownloaded, id]);

    const query = useMemo(() => localStorage['download:' + id] as string, [id]);
    if (!query)
        return <Navigate replace to="/" />;

    const host = determineAPIHost(path);
    const postUrl = host + path + '?format=csv&token=' + encodeURIComponent(String(auth.token)) + '&stream-type=chunked';

    return <Template>
        <div className="container pt-10">
            <p>
                <span className="inline-flex bg-green-500 text-white rounded-full w-8 h-8 items-center justify-center mr-2">
                    <i className="icon-download" />
                </span>
                Your download should be starting momentarily...
            </p>
            {allowClick && <div className="mt-5">
                <p>File not downloading? Use the button below to start the download.</p>
            </div>}

            <form action={postUrl} method="POST" encType="multipart/form-data">
                <input type="hidden" name="query" value={query} />
                <div className={allowClick ? '' : 'hidden'}>
                    <Button ref={buttonRef} color="orange">Download</Button>
                </div>
            </form>
        </div>
    </Template>;
}