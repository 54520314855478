import Template from '../../../components/template';
import AuthWall from '../../../components/auth-wall';
import {SiteFeature} from '../../../lib/check-access';
import React, {useContext, useEffect, useMemo, useState} from 'react';
import PageMenu from '../../../components/page-menu';
import Button from '../../../components/button';
import {useNavigate, useParams} from 'react-router-dom';
import Select from '../../../components/select';
import {CRBMetricTypes, CRBRegions, CRBReportTypes, CRBTypesOfService} from './constants';
import InsightContainer from '../../../insight-container';
import Loading from '../../../components/loading';
import {Link} from 'react-router-dom';
import SaveModal from '../../../components/save-modal';
import {insightContainer} from '../../../lib/api';
import handleError from '../../../lib/error';
import ErrorMessage from '../../../components/error-message';
import Variable from '../../../lib/metadata';
import Tooltip from '../../../components/tooltip';
import fetchRegions from './regions';
import CRBQueryBuilder from './queries';
import DataLibraryModal, {basicMDUpdateStrategy} from '../../../components/data-library-modal';
import {CRBLibrary} from './library';
import activityTracker from '../../../lib/activity-tracker';
import {DataConfig} from '../../../insight-container/types';
import {HeaderContext} from '../../../components/header/context';
import Breadcrumb from '../../../components/breadcrumb';
import {useFavorite} from '../../../lib/favorites';
import {StarFilled, Star, Information, Document} from '@carbon/icons-react';

const DefaultFields = ['admits', 'los'];

const CRB_TITLE = 'Commercial Reimbursement Benchmarks';

type CRBParams = {
    region?: string,
    reportType?: string,
    measure?: string,
    metricType?: string,
    qualifier?: string,
}

export default function CRBenchmarks() {
    const params = useParams<CRBParams>();
    const [regionType, setRegionType] = useState(params.region || CRBRegions[0].value);
    const [reportType, setReportType] = useState(params.reportType || CRBReportTypes[0].value);
    const [metricType, setMetricType] = useState(params.metricType || CRBMetricTypes[0].value);
    const [typeOfService, setTypeOfService] = useState((reportType === 'breakdown' && params.qualifier) || CRBTypesOfService[0].value);
    const [region, setRegion] = useState(((reportType === 'drg' || reportType === 'mdc') && params.qualifier) || '');
    const [regions, setRegions] = useState<Array<{name: string, value: string}>>([]);
    const [saveModal, setSaveModal] = useState(false);
    const [metadata, setMetadata] = useState<Array<Variable>>([]);
    const [libVisible, setLibVisible] = useState(false);
    const [selection, setSelection] = useState<Array<Variable>>([]);
    const [error, setError] = useState('');
    const [queryBuilder, setQueryBuilder] = useState<CRBQueryBuilder>();
    const [library, setLibrary] = useState<CRBLibrary>();
    const navigate = useNavigate();
    const {useNewDesign} = useContext(HeaderContext);

    useEffect(() => {
        if ((reportType !== 'drg' && reportType !== 'mdc') || regions.length)
            return;

        const controller = new AbortController();
        fetchRegions(regionType, controller.signal)
            .then(setRegions)
            .catch(err => handleError(err, setError));
        return () => controller.abort();
    }, [regions.length, reportType, regionType]);

    useEffect(() => {
        setMetadata([]);
        setQueryBuilder(new CRBQueryBuilder(regionType, reportType));
    }, [regionType, reportType]);

    useEffect(() => {
        if (!queryBuilder)
            return;

        const controller = new AbortController();
        queryBuilder.loadMetadata(controller.signal).then(([metadata, library]) => {
            setLibrary(library);
            setSelection(prev => {
                if (!prev.length)
                    return metadata.filter(v => v.name === 'pct_medicare' || DefaultFields.includes(v.name));
                else
                    return metadata.filter(v => prev.find(pv => pv.name === v.name) || DefaultFields.includes(v.name));
            });
            setMetadata(metadata);
        });
        return () => controller.abort();
    }, [queryBuilder]);

    const dataConfig: DataConfig = useMemo(
        () => queryBuilder?.buildConfig(selection, regions, metricType, typeOfService, region) || {queries: []},
        [metricType, queryBuilder, region, regions, selection, typeOfService]
    );

    useEffect(() => {
        let url = `/cr-benchmarks/${regionType}/${reportType}/${metricType}`;
        if (reportType === 'breakdown')
            url += '/' + typeOfService;
        if ((reportType === 'drg' || reportType === 'mdc') && region)
            url += '/' + region;
        navigate(url);
        activityTracker.setRecentActivity('Commercial Reimbursement Benchmarks', reportType);
    }, [region, reportType, typeOfService, regionType, metricType]);

    function updateRegionType(newRegionType: string) {
        setRegionType(newRegionType);
        setRegion('');
        setRegions([]);
    }

    function createContainer(savedQueryId: number | null) {
        if (savedQueryId) {
            const options = Object.assign(dataConfig.options);
            delete options.hideExplore;
            insightContainer.create({
                type: 'cr-benchmarks',
                optionsJSON: JSON.stringify(dataConfig.options),
                savedQueryId,
            }).catch(err => handleError(err, setError));
        }
        setSaveModal(false);
    }

    const metricTypeVar = useMemo(() => metadata.find(v => v.name === 'metric_type'), [metadata]);
    const showVis = !!selection.length && !!metadata.length && ((reportType !== 'drg' && reportType !== 'mdc') || !!region);

    const CRBView = useNewDesign ? NewCRBView : DefaultCRBView;
    const viewProps = {
        regionType, 
        reportType, 
        metricType, 
        typeOfService, 
        region, 
        regions, 
        updateRegionType, 
        setReportType, 
        setMetricType, 
        setTypeOfService, 
        setRegion, 
        setRegions, 
        dataConfig, 
        showVis, 
        error, 
        setError, 
        setSaveModal, 
        setLibVisible, 
        metricTypeVar,
        metadata,
    };

    return <AuthWall siteFeature={SiteFeature.AccessCRBenchmarks}>
        <Template>
            <CRBView {...viewProps} />

            {saveModal && showVis && <SaveModal onClose={createContainer} graph={dataConfig.queries[0]} reference="cr-benchmarks" />}
            {libVisible && library && <DataLibraryModal graph={dataConfig.queries[0]} selection={selection} library={library}
                updateStrategy={basicMDUpdateStrategy}
                onClose={(_, md) => {setLibVisible(false); setSelection(md);}}/>}
        </Template>
    </AuthWall>;
}

interface CRBViewProps {
    regionType: string,
    reportType: string,
    metricType: string,
    typeOfService: string,
    region: string,
    regions: Array<{name: string, value: string}>,
    updateRegionType: (regionType: string) => void,
    setReportType: (reportType: string) => void,
    setMetricType: (metricType: string) => void,
    setTypeOfService: (typeOfService: string) => void,
    setRegion: (region: string) => void,
    setRegions: (regions: Array<{name: string, value: string}>) => void,
    dataConfig: DataConfig,
    showVis: boolean,
    error: string,
    setError: (error: string) => void,
    setSaveModal: (saveModal: boolean) => void,
    setLibVisible: (libVisible: boolean) => void,
    metricTypeVar?: Variable,
    metadata: Array<Variable>,
}

function DefaultCRBView(props: CRBViewProps) {
    const navigate = useNavigate();
    const {regionType, reportType, metricType, typeOfService, region, regions, updateRegionType, setReportType, setMetricType, setTypeOfService, setRegion, metricTypeVar, dataConfig, showVis, error, setError, setSaveModal, setLibVisible, metadata} = props;

    return <div className="pb-8">
        <PageMenu>
            <Button color="link" onClick={() => navigate('/healthcare-index')}><i className="icon-left" /> Back</Button><div className="flex-grow" />
            <div className="flex space-x-3">
                <Button color="orange" onClick={() => setLibVisible(true)} disabled={!metadata.length}><i className="icon-plus" /> Add Data</Button>
                <Button color="light" onClick={() => setSaveModal(true)} disabled={!showVis}>
                    <i className="icon-save" /> Save Data
                </Button>
            </div>

            <div className="center">
                <h1>{CRB_TITLE}</h1>
            </div>
        </PageMenu>

        <div className="container mt-10">
            {error && <ErrorMessage error={error} onClick={() => setError('')} />}
            <div className="mb-2">
            For more information on the methodology and sources, please see the <Link to="/cr-benchmarks/overview">Overview and Notes</Link>.
            </div>
            <div className="flex">
                <div className="w-1/4">
                    <div className="font-semibold uppercase text-sm tracking-wide text-blue-500 mt-2">
                    Region Type
                    </div>
                    <Select data={CRBRegions} value={regionType} onChange={updateRegionType}>
                        {_ => _.value}
                        {_ => _.name}
                    </Select>
                </div>
                <div className="w-1/4">
                    <div className="font-semibold uppercase text-sm tracking-wide text-blue-500 mt-2">
                    Report Type
                    </div>
                    <Select data={CRBReportTypes} value={reportType} onChange={setReportType}>
                        {_ => _.value}
                        {_ => _.name}
                    </Select>
                </div>
                <div className="w-1/4">
                    <div className="mt-2 text-sm">
                        <span className="font-semibold uppercase tracking-wide text-blue-500 mr-2">
                        Medicare Payment Type
                        </span>
                        {metricTypeVar && <Tooltip wide position="below" content={metricTypeVar.description}>
                            <i className="icon-info" />
                        </Tooltip>}
                    </div>
                    <Select data={CRBMetricTypes} value={metricType} onChange={setMetricType}>
                        {_ => _.value}
                        {_ => _.name}
                    </Select>
                </div>
                {reportType === 'breakdown' && <div className="w-1/4">
                    <div className="font-semibold uppercase text-sm tracking-wide text-blue-500 mt-2">
                    Type of Service
                    </div>
                    <Select data={CRBTypesOfService} value={typeOfService} onChange={setTypeOfService}>
                        {_ => _.value}
                        {_ => _.name}
                    </Select>
                </div>}
                {(reportType === 'drg' || reportType === 'mdc') && <div className="w-1/4">
                    <div className="font-semibold uppercase text-sm tracking-wide text-blue-500 mt-2">
                        {regionType === 'msa' && 'MSA/Division'}
                        {regionType === 'state' && 'State'}
                    </div>
                    {regions.length ?
                        <Select rightAlign data={regions} value={region} onChange={setRegion} searchable>
                            {_ => _.value}
                            {_ => _.name}
                        </Select> :
                        <Loading small />}
                </div>}
            </div>
        </div>

        <div className="mt-5 mx-10">
            {showVis && <InsightContainer type="cr-benchmarks" dataConfig={dataConfig} />}
            {!showVis && (reportType === 'drg' || reportType === 'mdc') && <div className="container text-gray-500 text-right">
                <div className="mr-10">
                Please select a region to view the data. <i className="icon-arrow-up text-orange-500" />
                </div>
            </div>}
        </div>
    </div>;
}

function NewCRBView(props: CRBViewProps) {
    const {regionType, reportType, metricType, typeOfService, region, regions, updateRegionType, setReportType, setMetricType, setTypeOfService, setRegion, metricTypeVar, dataConfig, showVis, error, setError} = props;
    const [myFavorites, setFavorite] = useFavorite(CRB_TITLE);

    const isFavorite = useMemo(() => myFavorites.some(favorite => favorite.url === window.location.pathname), [myFavorites]);

    const FavoriteIcon = isFavorite ? StarFilled : Star;

    return <div className='px-10 py-8 bg-background-gray-100'>
        <Breadcrumb crumbs={[{name: 'Home', url: '/'}, {name: CRB_TITLE, url: '/'}]} />
        <div className='flex mt-6 mb-8 leading-10 gap-3 items-center'>
            <FavoriteIcon size={32} className='text-gray-800 hover:cursor-pointer' onClick={setFavorite} />
            <h1 className='text-gray-800 text-[32px] font-light m-0'>{CRB_TITLE}</h1>
            <div className='grow'></div>
            <div className='text-sm align-middle'>
                <Link to="/cr-benchmarks/overview"><Document className='inline pb-1' /> Overview and Notes</Link>
            </div>
        </div>
        <div className="mt-5">
            {error && <ErrorMessage error={error} onClick={() => setError('')} />}
            <div className="flex gap-2">
                <div className="w-1/4">
                    <div className="font-semibold uppercase text-sm tracking-wide mt-2">
                    Region Type
                    </div>
                    <Select data={CRBRegions} value={regionType} onChange={updateRegionType}>
                        {_ => _.value}
                        {_ => _.name}
                    </Select>
                </div>
                <div className="w-1/4">
                    <div className="font-semibold uppercase text-sm tracking-wide mt-2">
                    Report Type
                    </div>
                    <Select data={CRBReportTypes} value={reportType} onChange={setReportType}>
                        {_ => _.value}
                        {_ => _.name}
                    </Select>
                </div>
                <div className="w-1/4">
                    <div className="mt-2 text-sm">
                        <span className="font-semibold uppercase tracking-wide mr-2">
                        Medicare Payment Type
                        </span>
                        {metricTypeVar && <Tooltip wide position="below" alignment='bottom' content={metricTypeVar.description}>
                            <Information size={12} />
                        </Tooltip>}
                    </div>
                    <Select data={CRBMetricTypes} value={metricType} onChange={setMetricType}>
                        {_ => _.value}
                        {_ => _.name}
                    </Select>
                </div>
                {reportType === 'breakdown' && <div className="w-1/4">
                    <div className="font-semibold uppercase text-sm tracking-wide mt-2">
                    Type of Service
                    </div>
                    <Select data={CRBTypesOfService} value={typeOfService} onChange={setTypeOfService}>
                        {_ => _.value}
                        {_ => _.name}
                    </Select>
                </div>}
                {(reportType === 'drg' || reportType === 'mdc') && <div className="w-1/4">
                    <div className="font-semibold uppercase text-sm tracking-wide mt-2">
                        {regionType === 'msa' && 'MSA/Division'}
                        {regionType === 'state' && 'State'}
                    </div>
                    {regions.length ?
                        <Select data={regions} value={region} onChange={setRegion} searchable>
                            {_ => _.value}
                            {_ => _.name}
                        </Select> :
                        <Loading small />}
                </div>}
            </div>
        </div>

        <div className="mt-5">
            {showVis && <InsightContainer type="cr-benchmarks" dataConfig={dataConfig} />}
            {!showVis && (reportType === 'drg' || reportType === 'mdc') && <div className="container text-gray-500 text-right">
                <div className="mr-10">
                Please select a region to view the data. <i className="icon-arrow-up text-orange-500" />
                </div>
            </div>}
        </div>
    </div>;
}