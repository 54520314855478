import {FeatureCollection} from 'geojson';
import {SymbolLayout} from 'mapbox-gl';
import {CirclePaint, FillPaint, HeatmapPaint, SymbolPaint} from 'mapbox-gl';

export type LayerProps = {
    id: number | string;
    data: FeatureCollection | undefined,
    paint?: FillPaint | HeatmapPaint | CirclePaint | SymbolPaint,
    layout?: SymbolLayout,
    cluster?: boolean;
}

export function isFillPaint(value: any): value is FillPaint {
    return !!value['fill-color'];
}

export function isSymbolPaint(value: any): value is SymbolPaint {
    return !!value['icon-color'];
}

export type LayerPropsWithType = LayerProps & {
    layerType: Layers;
}

export enum Layers {
    point = 'point',
    choropleth = 'choropleth',
    circle = 'circle'
}

export type BaseMapProps = {
    children: LayerPropsWithType[]
}